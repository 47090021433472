"use client";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useContext } from "react";
import Link from "next/link";
import { useFormik } from "formik";
import * as Yup from "yup";
import { logIn } from "@/api/user";
import { MessageContext } from "@/contexts/MessageProvider";
import { useRouter } from "next/navigation";

const LoginForm = () => {
  const router = useRouter();
  const { addSuccessMessage, addErrorMessage } = useContext(MessageContext);

  const validationSchema = Yup.object({
    username: Yup.string().email("Invalid email format").required("Required"),
    password: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const res = await logIn(values);
      if (res.error) {
        addErrorMessage(res.error);
      } else {
        addSuccessMessage("Successfully logged in");
        router.push("/");
      }
    },
  });

  return (
    <div className="card p-component">
      <div className="flex flex-wrap align-items-center justify-content-center card-container blue-container m-7">
        <div className="surface-card p-4 shadow-2 border-round w-full lg:w-3">
          <form onSubmit={formik.handleSubmit}>
            <div className="text-center mb-5">
              <div className="text-900 text-3xl font-medium mb-3">
                Welcome Back
              </div>
            </div>

            <div>
              <div className="mb-3">
                <label
                  htmlFor="email"
                  className="block text-900 font-medium mb-2"
                >
                  Email
                </label>
                <InputText
                  type="text"
                  className="w-full"
                  id="username"
                  name="username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  autoComplete="username"
                />

                {formik.touched.username && formik.errors.username ? (
                  <div className="text-red-500 text-xs">
                    {formik.errors.username}
                  </div>
                ) : null}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="password"
                  className="block text-900 font-medium mb-2"
                >
                  Password
                </label>
                <InputText
                  type="password"
                  className="w-full"
                  id="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  autoComplete="current-password"
                />

                {formik.touched.password && formik.errors.password ? (
                  <div className="text-red-500 text-xs">
                    {formik.errors.password}
                  </div>
                ) : null}
              </div>

              <div className="flex align-items-center justify-content-between mb-6"></div>

              <Button
                label="Sign In"
                icon="pi pi-user"
                className="w-full"
                type="submit"
              />
              <div className="flex justify-content-center m-2">
                <div className="text-400">
                  {"Don't have an account"}
                  <span>
                    <Link
                      href="/signup"
                      className="text-400 text-color-secondary ml-2"
                    >
                      Sign up now!
                    </Link>
                  </span>
                </div>
              </div>
              <div className="flex justify-content-center m-2">
                <div className="text-400">
                  Forgot your password?
                  <span>
                    <Link
                      href="/restore-password"
                      className="text-400 text-color-secondary ml-2"
                    >
                      Click here for restore
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
